import request from '../utils/request'


//患教文章分组 分享
export function article_Share(data) {
    return request({
        url: "/share/article-group/wx-share",
        method: "post",
        data
    })
}