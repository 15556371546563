<template>
  <div class="article-main">
    <div class="header">
      <div class="search-head" >
        <span/>
        <input type="text" v-model="params.q" @blur="getList" :placeholder="$t('article.hintSearch')">
        <p class="seaxh-btn" @click="getList">{{$t("article.search")}}</p>
      </div>
      <div class="head-nav">
        <van-tabs v-model="tabsActive" line-width="1.48rem" line-height="0.2rem" @change="tabChange" class="mytabswz" color="#49A7FF"	title-inactive-color="#666666" title-active-color="#49A7FF">
          <van-tab v-for="item in extGroups" :title="item.name" :name="item.id" :key="item.id">
            {{item.name+item.id}}
          </van-tab>
        </van-tabs>
        <van-icon name="wap-nav more" @click="GroupShow = true" />
      </div>
    </div>

    <div class="section-box">
      <div class="nodata" v-if="articles.length==0">
        <img src="@/assets/img/data-null.png" >
      </div>
      <article-tab :articles='articles' v-if="articles.length>0"/>
    </div>

    <div class="diseaseList" v-show="isFilter">
      <ul class="disease-history clearfloat">
        <li :class="{'allSelect':params.gpId===0}" @click="getGroupParams('')">{{$t('article.entire')}}</li>
        <li v-for="item in groups" @click="getGroupParams(item)"
            :class="{'allSelect':params.gpId==item.id}">{{item.name}}
        </li>
      </ul>
      <div class="close-up" @click="isFilter=false"></div>
    </div>
    <van-overlay :show="GroupShow" class-name="area-boxd">
      <div class="area-close" @click="GroupShow = false"></div>
      <div class="wrapper" >
        <!-- extGroups -->
        <p class="title">
          <span class="txt">{{$t('article.GroupAll')}}</span>
          <van-icon name="cross" @click="GroupShow = false" />
        </p>
        <div class="groupList">
          <span class="item-box" :class="{'item-active':tabsActive == item.id}" v-for="(item,index) in extGroups" :key="index" @click="selectGroup(item)">
            {{item.name}}
          </span>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { article_list } from "@/api/article";
import ArticleTab from "./components/articleTab";
import wxConfig from '@/utils/wxconfig';
import { article_Share } from "@/api/share";
  export default {
    name: 'index',
    components: {ArticleTab},
    data() {
      return {
        params: {
          gpId: 0,
          q: ''
        },
        tabsActive:this.$route.query.groupId?Number(this.$route.query.groupId):0,
        articles: [],
        pageNum:1,
        isFilter: false,
        GroupShow:false,
        groups: [],
        extGroups: []
      }
    },
    created() {
      console.log(this);
      if(this.$route.query.groupId){
        this.tabsActive = Number(this.$route.query.groupId);
      }
      this.getList()
      this.getGroups()
      document.title = this.$t("article.pageTitle");
    },
    mounted(){
      this.setWXshare();
    },
    methods: {
      async getList() {
        // this.articles = [];
        const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message:  this.$t("message.loadingTxt")
        });
        this.params.pageNum = this.pageNum;
        this.params.pageSize = 10000;
        this.params.groupId = this.tabsActive;
        let result = await article_list(this.params);
        toast.close();
        console.log(result);
        if(result.data.errorCode == 0){
          if(this.pageNum==1){
            this.articles = result.data.data.data;
          }
        }else{
          this.articles = [];
        }
        // this.$axios1.post("/article/list", this.params).then(res => {
        //   this.articles = res.data.data;
        // })
      },
      tabChange(e){
        // console.log(e);
        this.pageNum = 1;
        this.$router.push({
            query: {
                groupId: e,
            },
        });
        this.getList();
        this.setWXshare();
      },
      setWXshare(){
        let datadd = {
          groupId:this.tabsActive,
          srcUrl:window.location.href
        }
        article_Share(datadd).then(res=>{
          this.setWxConfig(res.data);
        });
      },
      setWxConfig(data) {
        let self = this;
        wxConfig.share({
          title: data.title,
          desc: data.desc,
          imgUrl: data.imgUrl,
          link: data.toUrl,
          circleFriend: function() {
            self.changeShareState(data.pageTraceId, true);
          },
          failFriend: function() {
            self.changeShareState(data.pageTraceId, false);
          }
        });
      },
      changeShareState(pageTraceId, state) {
        let self = this;
        setTimeout(function() {
          self.$axios1.post("/share/wx-share-click", { pageTraceId }).then(res => {
            if (res.data) {
              self.setWxConfig(res.data);
            }
          });
        }, 500)
      },
      selectGroup(item){
        if(this.tabsActive != item.id){
          this.tabsActive = item.id;
          this.pageNum = 1;
          this.$router.push({
              query: {
                  groupId: item.id,
              },
          });
          this.GroupShow = false;
          this.getList();
          this.setWXshare();
        }
      },
      getGroups() {
        this.groups = []
        this.extGroups = []
        this.$axios1.post("/article/group/query").then(res => {
          // for (let i = 0; i < res.data.length; i++) {
          //   var item = res.data[i]
          //   if (item.groupFunction === 124001) {
          //     this.groups.push(item)
          //   } else {
          //     this.extGroups.push(item)
          //   }
          // }
          if(res.errorCode == 0){
            this.extGroups = res.data;
          }else{
            this.extGroups = [];
          }
          this.extGroups.unshift({
            id:0,
            name:this.$t("article.entire")
          })
          console.log(this.extGroups);
        })
      },
      getGroupParams(data) {
        this.isFilter = false
        if (data && data.id !== this.params.gpId) {
          this.params.gpId = data.id
        } else {
          this.params.gpId = 0
        }
        this.getList()
      }
    }
  }
</script>
<style lang="scss" scoped>
.head-nav{
  display: flex;
  align-items: center;
  padding:0 .6rem;
  .mytabswz{
    flex: 1;
    padding:0 .2rem;
    overflow: hidden;
    .van-tabs__wrap{
      height: 1.6rem;
    }
    .van-tabs__content{
      display: none;
    }
  }
  .more{
    flex: auto 0 0;
    margin-left: .2rem;
    padding: .1rem;
    font-weight: bold;
    font-size: .9rem;
    color: #000;
  }
}
</style>
<style lang="scss" scoped>
.article-main{
  width: 100%;background-color: #fafafa;
  height: 100%;
  display: flex;
  flex-direction: column;


  .nodata{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 8rem;
      height: auto;
    }
  }
  .header {
    width: 100%;
    flex: auto 0 0;
    // position: fixed;
    // top: 0;
    // z-index: 9;
    background: #fff;
    // height: 3.75rem;
    overflow: hidden;
    .search-head {
      height: 1.55rem;
      background: #F5F6FA;
      border-radius: 1.5rem;
      line-height: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: .6rem 2.5rem 0.1rem .6rem;
      span {
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        background: url("../../assets/images/search.png") no-repeat;
        background-size: 100% 100%;
      }
      .seaxh-btn{
        font-size: .64rem;
        font-weight: 400;
        color: #49A7FF;
        position: absolute;
        line-height: .6rem;
        padding: .2rem;
        right: -1.9rem;
        top: calc(50% - 0.5rem);
      }
      input {
        margin-left: 0.5rem;
        width: 80%;
        height: 1.5rem;
        line-height: 1.5rem;
        color: #666;
        background: none;
        font-size: 0.65rem;
      }
    }
    .date-read-filter {
      color: #999999;
      font-size: 0.65rem;
      display: flex;
      flex-direction: row;
      span {
        margin-left:0.5rem;
        border-radius: 1.5rem;
        background: #FFFFFF;
        b {
          font-weight: normal;
          padding: 0 0.5rem;
        }
        .color-blue {
          color: #3F9EFF
        }
      }
      .bg-blue {
        background: #D9EBFF;
      }
    }
    .bottom-line {
      background-color: white;
      height: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      margin-top: 1rem;
    }

  }
  .section-box{
    padding-bottom: .2rem;
    // margin-top: -0.5rem;
    flex: 1;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: #fff;
    position: relative;
    overflow: hidden;
    .article-con{
      overflow-y: scroll;
      height: 100%;
    }
    .filter {
      height: 1.5rem;
      top: 0.5rem;
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.75rem;
      font-size: 0.5rem;
      color: #999999;
      .filter-btn {
        padding: 0.2rem 0.5rem;
        background-color: #F4F4F5;
        border-radius: 0.5rem;
        img {
          width: 0.5rem;
          height: 0.55rem;
        }
      }
    }
  }
  .diseaseList {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .close-up {
    width: 1rem;
    height: 1rem;
    background: url("../../assets/images/close-up.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    right: 0.5rem;
    top: 1.75rem;
  }

  .disease-history {
    width: 100%;
    padding-top: 1.5rem;
    background: #fff;
    li {
      background: #fafafa;
      padding: 0.3rem 0.45rem;
      border-radius: 0.75rem;
      font-size: 0.7rem;
      color: #666666;
      float: left;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .allSelect {
      background: #EBF8FE;
      color: #03A0E9;
      margin-left:.6rem;
    }
  }
  .area-boxd{
    display: flex;
    flex-direction: column;
    .area-close{
      flex: 1;
    }
    .wrapper{
      flex: auto 0 0;
      background: #fff;
      border-top-left-radius: .16rem;
      border-top-right-radius: .16rem;
      min-height: 12rem;
      .title{
        display: flex;
        height: 1.8rem;
        align-items: center;
        font-size: .72rem;
        font-family: PingFang SC;
        font-weight: 600;
        color: #1A1A1A;
        padding: 0 .6rem;
        span{
          flex: 1;
        }
        .van-icon {
          flex: auto 0 0;
          padding: .2rem;
          font-size: .8rem;
          color: #000;
        }
      }
      .groupList{
        max-height: 18rem;
        overflow: auto;
        padding: 0 .6rem;
        margin:.2rem 0;
        .item-box{
          font-size: .64rem;
          font-family: PingFang SC;
          font-weight: 400;
          padding: .3rem .4rem;
          background: #ECEEF3;
          display: inline-block;
          border-radius: .08rem;
          margin-right: .4rem;
          margin-bottom: .4rem;
        }
        .item-active{
          background: #F5F6FA;
          color: #49A7FF;
          border:.04rem solid #49A7FF;
          padding: .26rem .36rem;
        }
      }
    }
  }
}
</style>
